// chatbot/gui/src/App.js

import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DOMPurify from 'dompurify';
import './App.css'
import SarvLogoIcon from './sarv-logo-icon';

// Set log level from environment variable
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'info';

const ChatMessage = ({ message, isUser }) => {
  const sanitizedHtml = DOMPurify.sanitize(message);

  return (
    <div
      className={`message`}
      style={{ justifyContent: isUser ? 'flex-end' : 'flex-start' }}
    >

      {!isUser && <div className='profileIcon'>
        <SarvLogoIcon />
      </div>}

      <div className={`${isUser ? "messageRight" : "messageLeft"}`}>
        {!isUser && <strong> Sarv CAI</strong>}
        {isUser ? (
          <p>{message}</p>
        ) : (
          <ReactMarkdown
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={solarizedlight}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              },
              p: ({ node, ...props }) => <p style={{ margin: '5px 0' }} {...props} />,
              a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />,
            }}
          >
            {sanitizedHtml}
          </ReactMarkdown>
        )}
      </div>

    </div>
  );
};

const App = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [debugMode, setDebugMode] = useState(false);
  const [voiceMode, setVoiceMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nextFiller, setNextFiller] = useState('');
  const messagesEndRef = useRef(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const enableDev = urlParams.get('enableDev')

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = async () => {
    if (input.trim() === '' || isLoading) return;

    const userMessage = input.trim();
    setMessages(prevMessages => [...prevMessages, { text: userMessage, isUser: true }]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await fetch('https://cai-travelspoc.sarv.com/api/chat', {
      //const response = await fetch('http://10.110.108.203:8004/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: userMessage,
          conversation_history: messages.map(m => ({ role: m.isUser ? 'user' : 'assistant', content: m.text })),
          debug: debugMode ? 'on' : 'off',
          voice_mode: voiceMode ? 'on' : 'off',
          next_filler: nextFiller
        }),
      });

      const data = await response.json();

      const fullAnswer = voiceMode ? `${nextFiller} ${data.main_answer}` : data.main_answer;
      setMessages(prevMessages => [...prevMessages, { text: fullAnswer, isUser: false }]);
      setNextFiller(data.next_filler);

      if (debugMode && data.debug_info) {
        console.log('Debug Info:', data.debug_info);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, { text: 'Sorry, an error occurred.', isUser: false }]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDebugMode = () => {
    setDebugMode(!debugMode);
    if (LOG_LEVEL === 'debug') {
      console.log(`Debug mode ${!debugMode ? 'enabled' : 'disabled'}`);
    }
  };

  const toggleVoiceMode = () => {
    setVoiceMode(!voiceMode);
    if (LOG_LEVEL === 'debug') {
      console.log(`Voice mode ${!voiceMode ? 'enabled' : 'disabled'}`);
    }
  };

  return (
    <div className='mainContainer'>
      <div className='mainContainerInner'>
        <h1 className="heading" style={{ textAlign: 'center' }}>Welcome to Sarv CAI</h1>
        <div className='messageBox'>
          {messages?.length ? messages.map((message, index) => (
            <ChatMessage key={index} message={message.text} isUser={message.isUser} />
          ))
            :
            <div className='noMessageContainer'>
              <div className='noMessageContainerInner'>
                <img src={'./images/no-message.jpg'} alt="no message" />
                <p className="noMessages">How can i help you?</p>
              </div>
            </div>
          }
          <div ref={messagesEndRef} />
        </div>
        <div className='inputContainer'>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button
            onClick={handleSendMessage}
            style={{
              backgroundColor: isLoading ? '#cccccc' : '#0c66e4',
              color: 'white',
              border: 'none',
              cursor: isLoading ? 'not-allowed' : 'pointer'
            }}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...'
              : <svg width="32" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.94582 9.31555C1.42328 9.14137 1.41915 8.86034 1.95657 8.6812L21.0428 2.31913C21.5713 2.14297 21.8744 2.43878 21.7263 2.95706L16.2731 22.0433C16.1221 22.5717 15.8175 22.5901 15.5942 22.0877L11.9997 14.0001L17.9997 6.00017L9.99967 12.0001L1.94582 9.31555Z" fill="#ffffff" />
              </svg>
            }
          </button>
        </div>
        {enableDev && <div style={{ textAlign: 'center' }}>
          <button
            onClick={toggleDebugMode}
            style={{
              padding: '5px 10px',
              backgroundColor: debugMode ? '#28a745' : '#dc3545',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginRight: '10px'
            }}
          >
            Debug Mode: {debugMode ? 'ON' : 'OFF'}
          </button>
          <button
            onClick={toggleVoiceMode}
            style={{
              padding: '5px 10px',
              backgroundColor: voiceMode ? '#28a745' : '#dc3545',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Voice Mode: {voiceMode ? 'ON' : 'OFF'}
          </button>
        </div>
        }
      </div>

      <div className='brandingSection'>
        <p>
          Powered by Sarv
        </p>
      </div>
    </div>

  );
};

export default App;
